




































import { Vue, Component } from 'vue-property-decorator'
import ReportList from "@/modules/budget/monitoring/reports-constructor/report-list.vue";
import ParametersPanel from "@/modules/budget/monitoring/reports-constructor/parameters-panel.vue";
import { ReportParameters, JournalCreateRequest } from "@/modules/budget/monitoring/reports-constructor/common/types";

@Component({
    components: {
        'report-list': ReportList,
        'parameters-panel': ParametersPanel
    }
})
export default class ReportsConstructor extends Vue {

    public journalParams: JournalCreateRequest | null = null;

    public selectedTemplate = null as ReportParameters | null

    private makeToast(message: string, title: string, variant: 'success' | 'danger' | '') {
        this.$bvToast.toast(message, {
            title: title,
            variant: variant,
            solid: true,
        })
    }
    
    public handleSelectTemplate(template: ReportParameters | null) {
        this.selectedTemplate = template
    }

    setJournalParams(params: JournalCreateRequest | null) {
        this.journalParams = params
    }

    public async sendToJournal() {
        if (!this.journalParams) {
            this.makeToast('Для выгрузки необходимо сформировать отчет', 'Сообщение', '')
            return
        }
        const response = await fetch('/api-py/monitoring/reports-constructor/journal',
            {
                method: 'POST',
                body: JSON.stringify(this.journalParams)
            }
        )
        if (response.ok) {
            this.makeToast('Сохранено в журнал', 'Сообщение', 'success')
        } else {
            this.makeToast('Не удалось сохранить в журнал', 'Ошибка', 'danger')
        }

        this.$emit('update-journal')
    }
}
