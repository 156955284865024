









































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { makeToast } from '@/modules/budget/monitoring/reports-constructor/common/utils'
import { nameMap } from '@/modules/budget/monitoring/reports-constructor/common/nameMap'
import { ReportParameters } from "@/modules/budget/monitoring/reports-constructor/common/types";
import { application2Parameters, report127Parameters } from "@/modules/budget/monitoring/reports-constructor/common/reportDefaultParameters";

@Component({
    name: "report-list"
})
export default class ReportList extends Vue {

    // #region Props
    @Prop({
        type: Function,
        required: true,
    })
    public onSelectReport!: Function;
    // #endregion

    get userId() {
        return this.$store.getters.user_uuid
    }

    // #region Data
    // TODO: implement filter
    private filter: any = {
        id: null,
        name: null,
        type: null,
        classification: null,
        description: null,
        dataSource: null,
    }

    public defaultTemplates = [] as ReportParameters[]
    public sharedTemplates = [] as ReportParameters[]
    public personalTemplates = [] as ReportParameters[]
    // #endregion

    public async created() {
        this.fillDefaultTemplates()
        await this.fillCustomTemplates()
    }

    private fillDefaultTemplates() {
        this.defaultTemplates.push(JSON.parse(JSON.stringify(application2Parameters)))
        this.defaultTemplates.push(JSON.parse(JSON.stringify(report127Parameters)))
            // this.reportList.push(
            //     this.createReportTemplate(
            //         "PR2",
            //         "regulated",
            //         "income",
            //         "Приложение 2",
            //         "Анализ причин перевыполнения или неисполнения плана с начала года по видам поступлений в республиканский и местные (бюджет области (города республиканского значения, столицы), областной бюджет, бюджет района (города областного значения), районный бюджет, бюджет города районного значения, села, поселка, сельского округа) бюджеты",
            //         "-",
            //         "thousand",
            //         "mixed",
            //         [
            //             "planned_revenue",
            //             "actual_revenue",
            //             "deviation",
            //             "plan_execution_percentage",
            //         ],
            //         "month",
            //     ),
            //     this.createReportTemplate(
            //         "PR3",
            //         "regulated",
            //         "income",
            //         "Приложение 3",
            //         "Анализ поступлений в республиканский и местные (бюджет области (города республиканского значения, столицы) бюджеты за отчетный период текущего года в сравнении с аналогичным периодом прошлого года",
            //         "-",
            //         "thousand",
            //         "iskk",
            //         [
            //             "actual_revenue_current_year",
            //             "actual_revenue_previous_year",
            //             "deviation",
            //             "growth_rate_decrease",
            //         ],
            //         "month",
            //     ),
            //     this.createReportTemplate(
            //         "PR7",
            //         "regulated",
            //         "expenses",
            //         "Приложение 7",
            //         "Информация-напоминание о непринятых обязательствах и несвоевременном выполнении плана финансирования по платежам в разрезе бюджетных программ (подпрограмм)",
            //         "-",
            //         "thousand",
            //         "iskk",
            //         [
            //             "adjusted_budget",
            //             "payment_plan",
            //             "commitments_plan",
            //             "paid_commitments",
            //             "execution_percentage",
            //             "unexecuted_amount",
            //             "accepted_commitments",
            //             "unaccepted_commitments_sum",
            //         ],
            //         "date",
            //     ),
            //     this.createReportTemplate(
            //         "PR9",
            //         "regulated",
            //         "expenses",
            //         "Приложение 9",
            //         "Аналитический отчет об исполнении местного бюджета",
            //         "-",
            //         "thousand",
            //         "iskk",
            //         [
            //             "fin_plan_year",
            //             "for_commitments",
            //             "for_payments",
            //             "accepted_commitments",
            //             "unaccepted_commitments_amount",
            //             "paid_commitments",
            //             "percent_completion",
            //             "expected_completion_year",
            //             "percent_expected_completion",
            //             "expected_non_completion_amount_year",
            //             "payment_plan_nonfulfillment",
            //             "budget_savings_total",
            //             "savings_from_government_procurement",
            //             "savings_from_wage_fund",
            //             "other_savings",
            //             "unallocated_reserve_balance_government_mio",
            //             "unallocated_reserve_balance_presidential_initiatives",
            //             "non_use_reporting_period",
            //             "unfulfilled_contractual_obligations_supplierss",
            //             "failed_state_procurement_tenders",
            //             "abp_budget_program_management",
            //             "other_reasons",
            //             "note_abp_justification_reasons_non_use_reporting_period",
            //             "note_abp_justification_reasons_untimely_acceptance_non_acceptance_obligations",
            //         ],
            //         "quarter",
            //     ),
            //     this.createReportTemplate(
            //         "R420",
            //         "working",
            //         "expenses",
            //         "4-20",
            //         "Сводный отчет по расходам по 8.4.2024 по бюджетной классификации",
            //         "-",
            //         "thousand",
            //         "iskk",
            //         [
            //             "fin_plan_year",
            //             "for_commitments",
            //             "for_payments",
            //             "accepted_commitments",
            //             "paid_commitments",
            //             "paid_commitments_month",
            //             "unpaid_obligations",
            //             "unfulfilled_obligations",
            //             "remaining_funds_from_obligation_plan",
            //             "remaining_funds_from_payment_plan",
            //         ],
            //         "month",
            //     ),
            //     this.createReportTemplate(
            //         "R542",
            //         "working",
            //         "expenses",
            //         "5-42",
            //         "Отчет о кассовом исполнении по форме 5-42",
            //         "-",
            //         "tg",
            //         "iskk",
            //         [
            //             "skor_annual_plan",
            //             "skor_reporting_month_plan",
            //             "skor_current_month_plan",
            //             "reporting_month_cash_execution",
            //             "current_month_cash_execution",
            //             "daily_cash_execution",
            //             "annual_remaining_balance",
            //             "current_period_remaining_balance",
            //             "annual_execution_percentage",
            //             "current_period_execution_percentage",
            //         ],
            //         "month",
            //     ),
            //     this.createReportTemplate(
            //         "control",
            //         "working",
            //         "expenses",
            //         "Остаток на контрольных счетах наличности",
            //         "Остаток на контрольных счетах наличности",
            //         "-",
            //         "tg",
            //         "iskk",
            //         ["cash_control_account_balance"],
            //         "date",
            //     ),
            //     this.createReportTemplate(
            //         "income",
            //         "working",
            //         "income",
            //         "Исполнение доходной части",
            //         "Исполнение доходной части",
            //         "-",
            //         "thousand",
            //         "mixed",
            //         [
            //             "annual_plan",
            //             "reporting_period_plan",
            //             "execution_percentage",
            //             "deviation",
            //         ],
            //         "date",
            //     ),
            //     this.createReportTemplate(
            //         "outcome",
            //         "working",
            //         "expenses",
            //         "Исполнение расходной части",
            //         "Исполнение расходной части",
            //         "-",
            //         "thousand",
            //         "iskk",
            //         [
            //             "annual_plan",
            //             "reporting_period_plan",
            //             "execution_percentage",
            //             "deviation",
            //         ],
            //         "date",
            //     ),
            // );
    }

    private async fillCustomTemplates() {
        try {
            const response = await fetch(`/api-py/monitoring/reports-constructor/templates/${this.userId}`)
            if (!response.ok) {
                throw new Error('Ошибка запроса получения шаблонов')
            }
            const templates = (await response.json()) as any[]
            templates.forEach(item => {
                const mapped: ReportParameters = {
                    id: item.id,
                    reportName: item.settings.reportName,
                    reportType: item.settings.reportType,
                    classification: item.settings.classification,
                    regions: item.settings.regions,
                    mutuallyRedeeming: item.settings.mutuallyRedeeming,
                    dataSource: item.settings.dataSource,
                    periodicity: item.settings.periodicity,
                    budgetType: item.settings.budgetType,
                    measureUnit: item.settings.measureUnit,
                    budgetStructure: item.settings.budgetStructure ?? null,
                    developType: item.settings.developType ?? null,
                    incomeTotal: item.settings.incomeTotal,
                    expenseTotal: item.settings.expenseTotal,

                    year: item.settings.year,
                    quarter: item.settings.quarter,
                    month: item.settings.month,
                    date: item.settings.date,

                    abpSummary: item.settings.abpSummary,
                    excludeZeroRows: item.settings.excludeZeroRows,
                    separateByRegion: item.settings.separateByRegion,
                    roundUp: item.settings.roundUp,
                    roundUpTo: item.settings.roundUpTo,

                    name: item.name,
                    description: item.description,
                    isShared: item.is_shared,

                    incomes: item.settings.incomes,
                    expenses: item.settings.expenses,

                    indicators: item.settings.indicators,

                    userId: item.user_id
                }
                if (item.is_shared) {
                    this.sharedTemplates.push(mapped)
                } else {
                    this.personalTemplates.push(mapped)
                }
            })
        } catch (error) {
            makeToast(this, 'Не удалось загрузить ваши шаблоны', 'Ошибка', 'danger')
        }
    }

    public async deleteTemplate(id: number, userId: string) {
        if (this.userId !== userId) {
            makeToast(this, 'Отсутсвует доступ для удаления', 'Сообщение', 'danger')
            return
        }

        const response = await fetch(`/api-py/monitoring/reports-constructor/templates/${id}`, {
            method: 'DELETE'
        })
        if (!response.ok) {
            makeToast(this, 'Не удалось удалить шаблон', 'Ошибка', 'danger')
            return
        }
        makeToast(this, 'Шаблон удален', 'Сообщение', 'success')

        this.personalTemplates = []
        this.sharedTemplates = []
        await this.fillCustomTemplates()
    }

    public readonly nameMap = nameMap
}
