































































































































































import VueElementLoading from "vue-element-loading"
import VueDraggable from 'vuedraggable'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Category, Indicator, TableField, Report127TableRow, SectionSeparator } from '@/modules/budget/monitoring/reports-constructor/common/types'
import { nameMap } from '@/modules/budget/monitoring/reports-constructor/common/nameMap'
import { formatDigitsGrouping, padLeadingZeros } from '@/modules/budget/monitoring/reports-constructor/common/utils'

interface Section {
    key: string,
    value: any[]
}

@Component({
    components: {
        'draggable': VueDraggable,
        'loading': VueElementLoading
    }
})
export default class Report127 extends Vue {

    @Prop({
        type: Array,
        required: true,
    })
    public tableRows!: (Report127TableRow | SectionSeparator)[]

    @Prop({
        type: Array,
        required: true,
        default: [],
    })
    private incomes!: Category[]

    @Prop({
        type: Array,
        required: true,
        default: [],
    })
    private expenses!: Category[]

    @Prop({
        type: Array,
        required: true,
        default: [],
    })
    private propIndicators!: Indicator[]

    public tableFields: TableField[] = []
    public categoryFields: TableField[] = []
    public indicators: Indicator[] = []

    public currentPage = 1
    public selectedRegion: Section | null = null
    public selectedSection: Section | null = null
    public currentItems: any[] = []
    public sectionOptions: Section[] = []
    // В случае если несколько регионов с подробными строками по каждой секций
    public regionSectionOptions: Section[] = []

    private indicatorKeys = ['utv', 'utch', 'plg', 'plgp', 'plgo', 'obz', 'nobz', 'sumrg', 'percentage1', 'percentage2']

    public indicatorsNameMap = {
        utv: 'Утвержденный бюджет на отчетный финансовый год',
        utch: 'Уточненный бюджет на отчетный финансовый год',
        plg: 'Скорректированный бюджет на отчетный финансовый год',
        plgs: 'Сводный план поступлений и финансирования по платежам, сводный план финансирования по обязательствам на отчетный период',
            plgp: 'по платежам',
            plgo: 'по обязательствам',
        obz: 'Принятые обязательства',
        nobz: 'Неоплаченные обязательства',
        sumrg: 'Исполнение поступлениий бюджета и/или оплаченных обязательств по бюджетным программам (подпрограммам)',
        percentage1: 'Исп-е поступ-ий бюджета и/или оплач. обяз-в по бюдж. прогр. (подпрогр.)  к свод. плану  поступ-ий и финанс-ия  на отчет. период, %',
        percentage2: 'Исп-е поступ-ий бюджета и/или оплач. обяз-ва по бюдж. прогр. (подпрогр.) к исполняемому бюджету, %',
    }

    get totalColumnSpan(): number {
        let span = 0
        for (const field of this.tableFields) {        
            if (this.indicatorKeys.includes(field.key)) {
                break
            }
            span++
        }
        return span
    }

    get categoriesSpan(): number {
        const activeIncomes = this.incomes.filter(item => item.active)
        const activeExpenses = this.expenses.filter(item => item.active)
        return activeExpenses.length > activeIncomes.length ? activeExpenses.length : activeIncomes.length
    }

    private created() {
        this.indicators = JSON.parse(JSON.stringify(this.propIndicators))
        this.constructTableFields(true)
        this.fillSections()
    }

    private fillSections() {
        const tempRegionOptions: Section[] = []
        let tempSectionOptions: Section[] = []
        let tempSectionRows: Report127TableRow[] = []
        let tempTableRows = this.tableRows
        if (this.tableRows[0].type !== 'REGION') {
            const temp: SectionSeparator = { 'name': '', type: 'REGION' }
            tempTableRows = [(temp as Report127TableRow | SectionSeparator)].concat(tempTableRows)
        }
        tempTableRows.forEach((row, index) => {
            if (this.isSectionSeparator(row)) {
                if (row.type === 'REGION') {
                    tempSectionOptions = []
                    tempRegionOptions.push({ key: row.name, value: tempSectionOptions })
                } else if (row.type === 'SECTION') {
                    tempSectionRows = []
                    tempSectionOptions.push({ key: row.name, value: tempSectionRows })
                }
            } 
            else {
                tempSectionRows.push(row)
            }
        })
        // #endregion

        this.regionSectionOptions = tempRegionOptions

        this.selectedRegion = tempRegionOptions[0]

        this.sectionOptions = tempRegionOptions[0].value
        this.selectedSection = ((tempRegionOptions[0]).value[0] as Section)

        this.handlePageChange(1)
    }

    private constructTableFields(isOnCreate: boolean) {
        const tempTableFields: TableField[] = []
        tempTableFields.push({ key: 'codes', label: 'Коды бюджетной классификации', active: true })

        let tempIncomes = this.incomes
        let tempExpenses = this.expenses

        if (tempIncomes.length == 0) {
            tempIncomes = [{ key: 'temp_income', selected: [], active: true }]
        }
        if (tempExpenses.length == 0) {
            tempExpenses = [{ key: 'temp_expenses', selected: [], active: true }]
        }

        const activeIncomes = tempIncomes.filter(item => item.active)
        const activeExpenses = tempExpenses.filter(item => item.active)
        
        const categoriesSpan = this.categoriesSpan === 0 ? 1 : this.categoriesSpan
        
        const tempCategoryFields: TableField[] = []
        for (let i = 0; i < categoriesSpan; i++) {
            const incomeKey = activeIncomes[i]?.key
            const expenseKey = activeExpenses[i]?.key
            // @ts-ignore
            const incomeLabel = nameMap[incomeKey]
            // @ts-ignore
            const expenseLabel = nameMap[expenseKey]

            const field: TableField = {
                key: `${incomeKey}/${expenseKey}`,
                label: `${incomeLabel??' - '}/${expenseLabel??' - '}`,
                income: incomeKey,
                expense: expenseKey,
                active: true
            }
            tempCategoryFields.push(field)
        }
        this.categoryFields = tempCategoryFields

        tempTableFields.push({ key: 'nameRu', label: 'Наименование', active: true })

        const indicators = isOnCreate ? this.propIndicators : this.indicators

        indicators.filter(it => it.active).forEach(indicator => {
            // @ts-ignore
            tempTableFields.push({ key: indicator.key, label: this.indicatorsNameMap[indicator.key], active: true })
        })

        this.tableFields = tempTableFields
    }

    public handlePageChange(page: number) {
        const indexedPage = page - 1
        this.currentPage = page
        const startIndex = indexedPage * 100
        const endIndex = startIndex + 100
        this.currentItems = this.selectedSection!.value.slice(startIndex, endIndex)
    }

    public onSectionChange() {
        this.handlePageChange(1)
    }

    public draggableHeader(columnKey: string) {
        return this.indicatorKeys.includes(columnKey)
    }

    public isTotalColumn(columnKey: string) {
        return ['plan', 'fact', 'delta1'].includes(columnKey)
    }

    public synchronizeIndicatorsOrder() {
        const fullIndicatorKeys = this.indicatorKeys.filter(it => ['plgp', 'plgo'].notIncludes(it)).concat(['plgs'])
        const indicatorsOrder = this.tableFields
            .filter(it => fullIndicatorKeys.includes(it.key))
            .map(it => it.key)

            fullIndicatorKeys.forEach(indicatorKey => {
            if (indicatorsOrder.notIncludes(indicatorKey)) {
                indicatorsOrder.push(indicatorKey)
            }
        })

        const tempIndicators: Indicator[] = []
        indicatorsOrder.forEach(indicatorKey => {
            const IndicatorObject = this.indicators.find(it => it.key === indicatorKey)!
            tempIndicators.push(IndicatorObject)
        })

        this.indicators = tempIndicators
        this.$emit('update-indicators', tempIndicators)
    }

    public synchronizeVisiblityInTable() {
        this.$emit('update-indicators', JSON.parse(JSON.stringify(this.indicators)))
        this.constructTableFields(false)
    }

    public leftPaddingBasedOnCode(value: number | string, code: string, type: 'INCOME' | 'EXPENSE'): string {
        let padSize = 1
        if (type === 'INCOME') {
            if (['cls', 'spf'].includes(code)) {
                padSize = 2
            }
            return this.padLeadingZeros(value, padSize)
        } else if (type === 'EXPENSE') {
            if (['gr', 'pgr'].includes(code)) {
                padSize = 2
            } else if (['abp', 'prg', 'ppr', 'spf'].includes(code)) {
                padSize = 3
            }
            return this.padLeadingZeros(value, padSize)
        } else {
            throw new Error("Unsupported type")
        }
    }

    private isSectionSeparator = (row: Report127TableRow | SectionSeparator): row is SectionSeparator => {
        return row.type === 'REGION' || row.type === 'SECTION'
    }

    public formatDigitsGrouping = formatDigitsGrouping
    public padLeadingZeros = padLeadingZeros
}
