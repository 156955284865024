


































import { Vue, Component } from 'vue-property-decorator'
import { makeToast } from '@/modules/budget/monitoring/reports-constructor/common/utils'
import { JournalRecord } from '@/modules/budget/monitoring/reports-constructor/common/types'
import { nameMap } from '@/modules/budget/monitoring/reports-constructor/common/nameMap'
import { Ax } from '@/utils'


const tempTableFields = [
    { key: 'id', label: 'ID' },
    { key: 'name', label: 'Наименование отчета' },
    { key: 'reportType', label: 'Вид' },
    { key: 'year', label: 'Год' },
    { key: 'region', label: 'Регион' },
    { key: 'cls', label: 'Кл' },
    { key: 'pcl', label: 'Подкл' },
    { key: 'abp', label: 'АБП' },
    { key: 'gu', label: 'ГУ' },
    { key: 'prg', label: 'ПРГ' },
    { key: 'mutuallyRedeeming', label: 'ВЗК' },
    { key: 'measureUnit', label: 'Ед. изм.' },
    { key: 'dataSource', label: 'Ист. данных' },
    { key: 'username', label: 'Пользователь' },
    { key: 'creationTimestamp', label: 'Дата и время создания' },
    { key: 'status', label: 'Статус' },
    { key: 'action', label: '' },
]

@Component
export default class Journal extends Vue {

    public records: JournalRecord[] = []

    public progress = 100

    public readonly tableFields = tempTableFields

    public updateTabRef(tabRef: string) {
        this.$emit('update-tab-ref', tabRef);
    }

    public handleSetReportParams(params: any) {
        this.$emit("set-report-params", params)
    }

    get userId(): string {
        return this.$store.state.user.sub;
    }

    private async created() {
        await this.loadJournalRecords()
    }

    public nameMap(mapItem: any, key: string): string {
        if (typeof mapItem === 'object' && (mapItem ?? false)) {
            let label = ''
            mapItem.forEach((item: any, index: number) => {
                label += item
                if ((index + 1) !== mapItem.length) {
                    label += ', '
                }
            })
            return label
        }
        
        // @ts-ignore
        const label = nameMap[mapItem]
        if (typeof label === 'string') {
            return label
        }

        if (key === 'creationTimestamp') {
            return this.formatDateString(mapItem)
        }

        return mapItem
    }

    private async loadJournalRecords() {
        try {
            this.progress = 50
            const response = await fetch(`/api-py/monitoring/reports-constructor/journal/${this.userId}`)
            if (!response.ok) {
                throw new Error('Ошибка загрузики журнала')
            } 
            const responseRecords = await response.json()
            this.progress = 70
            const tempRecords: JournalRecord[] = responseRecords.map((record: any) => {
                const temp: JournalRecord = {
                    id: record.id,
                    name: record.name,
                    reportName: record.settings.reportName,
                    reportType: record.settings.reportType,
                    year: record.settings.year,
                    region: record.settings.region,
                    cls: record.settings.incomes.find((income: any) => income.key === 'cls')?.selected,
                    pcl: record.settings.incomes.find((income: any) => income.key === 'pcl')?.selected,
                    abp: record.settings.expenses.find((expense: any) => expense.key === 'abp')?.selected,
                    gu: record.settings.expenses.find((expense: any) => expense.key === 'gu')?.selected,
                    prg: record.settings.expenses.find((expense: any) => expense.key === 'prg')?.selected,
                    mutuallyRedeeming: record.settings.mutuallyRedeeming,
                    measureUnit: record.settings.measureUnit,
                    dataSource: record.settings.dataSource,
                    username: record.username,
                    creationTimestamp: record.creation_timestamp,
                    status: 'ОБРАБОТАНО'
                }
                return temp
            })
            this.records = tempRecords
        } catch (error) {
            makeToast(this, 'Не удалось загрузить данные журнала', 'Ошибка', 'danger')
        } finally {
            this.progress = 100
        }
    }

    public async downloadReport(record: JournalRecord) {
        makeToast(this, 'Скоро начнется скачивание. Пожалуйста ожидайте.', 'Внимание!', 'info')
        this.progress = 10
        try {
            let endpointUrl = ''
            switch (record.reportName) {
                case 'PR2':
                    endpointUrl = "/api-py/monitoring/reports-constructor/excel/application-2"
                    break
                case '1-27':
                    endpointUrl = "/api-py/monitoring/reports-constructor/excel/1-27"
                    break
            }
            this.progress = 50
            const response = await fetch(endpointUrl, {
                method: 'POST',
                body: JSON.stringify({ journal_id: record.id })
            })
            if (!response.ok) {
                throw new Error('')
            }
            
            const blob = await response.blob()
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            const filename = record.name
            const extension = '.xls'
            link.href = url
            link.setAttribute('download', filename + extension)
            document.body.appendChild(link)
            link.click()
        } catch (error) {
            makeToast(this, 'Не удалось загрузить отчет', 'Ошибка', 'danger')
        } finally {
            this.progress = 100
        }
    }

    private formatDateString(dateString: string): string {
        const date = new Date(dateString);

        // Extract date components
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        // Extract time components
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        // Format the date and time components into the desired format
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
}
