import { ReportParameters } from "@/modules/budget/monitoring/reports-constructor/common/types"


const defaultIncomes = [
    { key: 'kat', active: false, selected: [] },
    { key: 'cls', active: false, selected: [] },
    { key: 'pcl', active: false, selected: [] },
    { key: 'spf', active: false, selected: [] }
]
const defaultExpenses = [
    { key: 'gr',  active: false, selected: [] },
    { key: 'pgr', active: false, selected: [] },
    { key: 'abp', active: false, selected: [] },
    { key: 'gu', active: false, selected: [] },
    { key: 'prg', active: false, selected: [] },
    { key: 'ppr', active: false, selected: [] },
    { key: 'spf', active: false, selected: [] },
]

export const application2Parameters: ReportParameters = {
    id: null,
    reportName: 'PR2',
    reportType: 'REGULATED',
    classification: 'INCOMES',
    regions: [],
    dataSource: 'MIXED',
    periodicity: 'QUARTER',
    budgetType: [],
    measureUnit: 'THOUSAND',
    mutuallyRedeeming: 'WITH',
    budgetStructure: null,
    developType: null,
    incomeTotal: false,
    expenseTotal: false,

    year: (new Date()).getFullYear(),
    quarter: null,
    month: null,
    date: null,

    abpSummary: false,
    excludeZeroRows: false,
    separateByRegion: false,
    roundUp: false,
    roundUpTo: null,

    name: 'Приложение 2',
    description: '',
    isShared: false,

    incomes: JSON.parse(JSON.stringify(defaultIncomes)),
    expenses: null,

    indicators: [
        { key: 'plan', active: true },
        { key: 'fact', active: true },
        { key: 'delta1', active: true },
        { key: 'delta2', active: true },
        { key: 'reasons', active: true },
    ]
}


export const report127Parameters: ReportParameters = {
    id: null,
    reportName: '1-27',
    reportType: 'REGULATED',
    classification: 'MIXED',
    regions: [],
    dataSource: 'LOADER',
    periodicity: 'MONTH',
    budgetType: [],
    measureUnit: 'THOUSAND',
    mutuallyRedeeming: 'WITH',
    budgetStructure: 'WITHOUT',
    developType: null,
    incomeTotal: false,
    expenseTotal: false,

    year: (new Date()).getFullYear(),
    quarter: null,
    month: null,
    date: null,

    abpSummary: false,
    excludeZeroRows: false,
    separateByRegion: false,
    roundUp: false,
    roundUpTo: null,

    name: 'Отчет 1-27',
    description: '',
    isShared: false,

    incomes: JSON.parse(JSON.stringify(defaultIncomes)),
    expenses: [
        { key: 'gr',  active: false, selected: [] },
        { key: 'pgr', active: false, selected: [] },
        { key: 'abp', active: false, selected: [] },
        { key: 'prg', active: false, selected: [] },
        { key: 'ppr', active: false, selected: [] },
        { key: 'spf', active: false, selected: [] },
    ],

    indicators: [
        { key: 'utv', active: true },
        { key: 'utch', active: true },
        { key: 'plg', active: true },
        { key: 'plgs', active: true }, // Родительнский показатель для plgp, plgo
        // { key: 'plgp', active: true }, 
        // { key: 'plgo', active: true },
        { key: 'obz', active: true },
        { key: 'nobz', active: true },
        { key: 'sumrg', active: true },
        { key: 'percentage1', active: true },
        { key: 'percentage2', active: true }
    ]
}
