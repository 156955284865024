import { render, staticRenderFns } from "./application-2.vue?vue&type=template&id=a5f4d898&scoped=true&"
import script from "./application-2.vue?vue&type=script&lang=ts&"
export * from "./application-2.vue?vue&type=script&lang=ts&"
import style0 from "./application-2.vue?vue&type=style&index=0&id=a5f4d898&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5f4d898",
  null
  
)

export default component.exports