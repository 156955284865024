






































































































































































































































import {Vue, Component} from 'vue-property-decorator';
import axios from 'axios';
import {Ax} from "@/utils";
import VueElementLoading from "vue-element-loading";

@Component({
    components: {
        'loading': VueElementLoading
    },
    filters: {
        formatDate: function (value:any) {
            if (value) {
                const date = new Date(value);
                return date.toLocaleDateString();
            }
            return null;
        }
    }
})
export default class ReportForm420 extends Vue{
    private cutness:any = null;
    private reg:any = null;
    private source:any = null;
    private date_to:any = null;
    private comparedPeriod:any = null;
    private user_id: any = '';
    private loading:boolean = false;
    private curPage:number = 1
    private perPage:number = 50;
    private is0000Check:boolean = false;
    private showOnlyErrors:boolean = false;
    private selectedAbp:any = null;
    private selectedGu:any = null;

    private modalForm:boolean = false;
    private referenceList:any[] = [];
    private currentRefernceStatus:any = null;
    private currRefObject:any = {};
    private requestTypeOptions:any =  {
        individual: {
            code: "individual",
            localText: "Внутренняя",
            name_ru: "Внутренняя",
        },
        movement: {
            code: "movement",
            localText: "Передвижка",
            name_ru: "Передвижка",
        },
        budget_adjustment: {
            code: "budget_adjustment",
            localText: "Корректировка",
            name_ru: "Корректировка",
        },
        budget_clarification: {
            code: "budget_clarification",
            localText: "Уточнение",
            name_ru: "Уточнение",
        },
        budget_income: {
            code: "budget_income",
            localText: "Поступления(скорректированные)",
            name_ru: "Поступления(скорректированные)",
        },
        budget_income_clarification: {
            code: "budget_income_clarification",
            localText: "Поступления(уточненные)",
            name_ru: "Поступления(уточненные)",
        },
        budget_income_movement: {
            code: "budget_income_movement",
            localText: "Поступления(передвижка)",
            name_ru: "Поступления(передвижка)",
        }
    }
    private larTableFields:any[] = [
        {
            key: 'number',
            label: 'Номер',
            thStyle: { width: "10%" },
        },
        {
            key: 'date',
            label: 'Дата согласования',
            thStyle: { width: "20%" },
        },
        {
            key: 'requestType',
            label: 'вид справки',
            thStyle: { width: "20%" },
        },
        {
            key: 'description',
            label: 'Основание',
            thStyle: { width: "60%" },
        }
    ]
    private regionsDict:any = {}
    private async loadRegionDict() {
        await axios.get(`/api-py/fulfillment-report/region-dicts/${this.reg.code.slice(0, 2)}/${this.formatedDate(this.date_to)}`).then(resp => {
            if (resp.status === 200) {
                for (const r of resp.data) {
                    this.regionsDict[r.code] = r
                }
            }
        })
    }
    private get calcFields() { return this.comparedPeriod.code? ['obaz', 'plat', 'sum_oblig', 'sum_payment'] : ['plg', 'sum_oblig', 'sum_payment']}
    private get totalTreeCollumn() {
        if (this.cutness.code === 0) return 1
        if (this.cutness.code === 1) return 2
        if (this.cutness.code === 2) return 5
    }
    private dictFunc:any = {}
    private dictGU:any = {}
    private dictSPF:any = {}
    private tableData:any[] = [];
    private get paginateTable() {
        const start = (this.curPage - 1) * this.perPage
        const end = start + this.perPage
        return this.tableData.slice(start, end)
    }
    private pageChanged() {
        const mainConParent = document.getElementById('main-container');
        if (mainConParent instanceof HTMLElement) {
            mainConParent.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }
    created() {
        this.$on('reportForm420', (region:any, date_to:any, source:any, cutness:any, comparedPeriod:any, uuid: string, selectedABP:any, selectedGU:any, is0000Check:boolean, showOnlyErrors:boolean) => {
            this.tableData = [];
            this.date_to = null;
            this.cutness = null;
            this.reg = null;
            this.source = null;
            this.comparedPeriod = null;
            this.selectedAbp = selectedABP
            this.selectedGu = selectedGU
            this.user_id = '';
            this.loadData(date_to, region, cutness, source, comparedPeriod, uuid, selectedABP, selectedGU, is0000Check);
            this.date_to = date_to;
            this.cutness = cutness;
            this.reg = region;
            this.source = source;
            this.comparedPeriod = comparedPeriod;
            this.user_id = uuid
            this.is0000Check = is0000Check
            this.showOnlyErrors = showOnlyErrors
            this.loadRegionDict()
        });
    }

    private formatedDate(date_to:Date) {
        return `${date_to.getFullYear()}-${date_to.getMonth() + 1}-${date_to.getDate()}`
    }
    private async loadData(date_to:Date, region:any, cutness:any, source: any, comparedPeriod:any,  uuid:string, selectedABP:any, selectedGU:any, is0000Check:boolean) {
        this.loading = true
        const that = this
        try {
            const formatedDate = `${date_to.getFullYear()}-${date_to.getMonth() + 1}-${date_to.getDate()}`
            const request = {
                'cutness': cutness.code,
                'region': region.code,
                'repdate': formatedDate,
                'source': source.code,
                'user_id': uuid,
                'compared_period': comparedPeriod.code,
                'displayZeroReg': is0000Check && region.code.slice(2) === '0000',
                'abp': !selectedABP || !selectedABP.abp? null: selectedABP.abp,
                'gu': !selectedGU || !selectedGU.gu? null: selectedGU.gu
            }
            axios.post('/api-py/fulfillment-report/420/data', request).then(resp => {
                if (resp.status === 200) {
                    this.collapse = true;
                    const isNull = (child:any) => {
                        if (this.comparedPeriod.code) {
                            return child['obaz'] === null && child['plat'] === null && child['sum_oblig'] === null && child['sum_payment'] === null;
                        } else {
                            return child['plg'] === null && child['sum_oblig'] === null && child['sum_payment'] === null;
                        }
                    }
                    const clearList = (data: any) => {
                        if ('child_list' in data) {
                            if ('dict_child_list' in data) {
                                delete data['dict_child_list'];
                            }
                            data['child_list'] = data['child_list'].filter((child: any) => !isNull(child));
                            for (const child of data['child_list']) {
                                clearList(child);
                            }
                        }
                    }
                    const data = resp.data;
                    // let tree = this.createTree(data.list, this.cutness.code, data.funcDict, data.guDict, data.spfDict)
                    const tree = data.list.filter((child:any) => !isNull(child));
                    // for (const el of data) {
                    //     clearList(el)
                    // }
                    this.dictFunc = data.funcDict
                    this.dictGU = data.guDict
                    this.dictSPF = data.spfDict
                    if (this.cutness.code === 0 && !(is0000Check)) {
                        tree.sort((a:any, b:any) => {return a.abp - b.abp;})
                    }
                    this.tableData = tree
                    this.tableData = this.tableData.filter((val:any) => {
                        return !this.showOnlyErrors || (this.showOnlyErrors && this.getBg(5, val))
                    })
                    that.curPage = 1
                    that.loading = false
                }
            }).catch(error => {})
        } catch (e) {
            that.loading = false
        }
    }

    private collapse: boolean = true;
    private clkCollapseAll(data: any, collapse = true) {
        this.$set(data, 'collapse', collapse);
        if ('child_list' in data && data.child_list && data.child_list.length) {
            for (const el of data.child_list) { this.clkCollapseAll(el, collapse) }
        } else if ('form_child_list' in data && data.form_child_list && data.form_child_list.length) {
            for (const el of data.form_child_list) { this.clkCollapseAll(el, collapse) }
        } else if ('spf_list' in data && data.spf_list && data.spf_list.length) {
            for (const el of data.spf_list) { this.clkCollapseAll(el, collapse) }
        }
    }
    private clkCollapse(abp: any = null, gu: any = null, prg: any = null, ppr: any = null, spf: any = null, collapse: boolean = false) {
        if (abp !== null) {
            let obj = this.tableData[abp]
            if (gu !== null) {
                obj = obj.child_list[gu]
            }
            if (prg !== null) {
                obj = obj.child_list[prg]
            }
            if (ppr !== null) {
                obj = obj.child_list[ppr]
            }
            if (collapse) {
                this.clkCollapseAll(obj, collapse)
            }
            this.$set(obj, 'collapse', collapse);
        }

        else {
            this.collapse = collapse;
            for (const el of this.tableData) {
                this.clkCollapseAll(el, collapse)
            }
        }
    }

    private getBg(data: number, item:any, data_type:any = 0, isSpf:boolean = true) {
        if (!item.gotChange) {
            if (!this.comparedPeriod.code) {
                if (item['obaz'] === null && item['sum_oblig'] === null) {
                    item['sum_oblig'] = 0;
                }
                if (item['plat'] === null && item['sum_payment'] === null) {
                    item['sum_payment'] = 0;
                }
            } else {
                if (item['plg'] === null && item['sum_oblig'] === null) {
                    item['sum_oblig'] = 0;
                }
                if (item['plg'] === null && item['sum_payment'] === null) {
                    item['sum_payment'] = 0;
                }
            }
            item.gotChange = true
        }
        const dataSource:any = {
            a: this.comparedPeriod.code? { 0: 'obaz', 1: 'plat' } : { 0: 'plg', 1: 'plg' },
            b: {
                0: 'sum_oblig',
                1: 'sum_payment'
            }
        }
        const srcA:string = dataSource.a[data_type]
        const srcB:string = dataSource.b[data_type]
        if (data === 0) {
            if ((item[srcB] !== null && item[srcA] !== null) || (!item[srcB] && !item[srcA])) {
                return (item[srcA] === item[srcB]) || (!item[srcB] && !item[srcA])? '#A9D08E': '#F4B084'
            } else {
                return item[srcB] !== null? '#F4B084': item[srcA] === 0? '#A9D08E': '#FFD966'
            }
        } else if (data === 1) {
            if ((item[srcB] !== null && item[srcA] !== null) || (!item[srcB] && !item[srcA])) {
                return (item[srcA] === item[srcB]) || (!item[srcB] && !item[srcA])? 'равны': this.$n(item[srcA] - item[srcB], "decimal1")
            }
            return item[srcB] !== null? this.$n(item[srcB], "decimal1") : item[srcA] === 0? 'равны': 'не заполнены'
        } else if (data === 2) {
            if (item[dataSource.b[1]] !== null && item[dataSource.b[0]] !== null) {
                return item[dataSource.b[0]] === item[dataSource.b[1]]? 'равны': 'не равны'
            }
            return 'не заполнены'
        } else if (data === 3) {
            if (((item.sum_oblig && item.sum_payment) ||
                (!item.plg && !item.sum_payment && item.sum_oblig) ||
                (!item.plg && item.sum_payment && !item.sum_oblig))) {
                return item[dataSource.b[0]] === item[dataSource.b[1]]? '#A9D08E': '#FF3B3B'
            } else {
                return '#FFD966'
            }
        } else if (data === 4) {
            if (['sum_oblig', 'sum_payment'].includes(data_type)) {
                const types_data:any = {
                    'sum_oblig': 0,
                    'sum_payment': 1
                }
                const data_t = types_data[data_type]
                return  item[data_type] !== null? this.$n(item[data_type], "decimal1") : item[dataSource.a[data_t]] === 0? 0: `не заполнено`
            } else {
                return item[data_type] !== null? this.$n(item[data_type], "decimal1"): `нет данных`
            }

        } else if (data === 5) {
            let isError0 = false;
            let isError1 = false;
            const srcA0:string = dataSource.a[0] //key a[0]
            const srcB0:string = dataSource.b[0] //key b[0]
            const srcA1:string = dataSource.a[1] //key a[1]
            const srcB1:string = dataSource.b[1] //key b[1]

            if ((item[srcB0] !== null && item[srcA0] !== null) || (!item[srcB0] && !item[srcA0])) {
                isError0 = !((item[srcA0] === item[srcB0]) || (!item[srcB0] && !item[srcA0]))
            } else {
                isError0 = item[srcB0] !== null? true: item[srcA0] !== 0
            }
            if ((item[srcB1] !== null && item[srcA1] !== null) || (!item[srcB1] && !item[srcA1])) {
                isError1 = !((item[srcA1] === item[srcB1]) || (!item[srcB1] && !item[srcA1]))
            } else {
                isError1 = item[srcB1] !== null? true: item[srcA1] !== 0
            }
            return  isError1 || isError0

        }
    }

    private get showRegCol() {
        return this.is0000Check && this.isZeroReg
    }
    private get isZeroReg() {
        return this.reg.code.slice(2) === '0000'
    }
    private downloadRep() {
        this.loading = true
        const formatedDate = `${this.date_to.getFullYear()}-${this.date_to.getMonth() + 1}-${this.date_to.getDate()}`
        const request = {
            'cutness': this.cutness.code,
            'region': this.reg.code,
            'repdate': formatedDate,
            'source': this.source.code,
            'user_id': this.user_id,
            'compared_period': this.comparedPeriod.code,
            'displayZeroReg': this.is0000Check && this.isZeroReg,
            'showOnlyErrors': this.showOnlyErrors,
            'abp': this.selectedAbp || !this.selectedAbp.abp? null: this.selectedAbp.abp,
            'gu': this.selectedGu || !this.selectedGu.gu? null: this.selectedGu.gu
        }
        const that = this
        try {
            this.makeToast('info', 'Внимание!', 'Скоро начнется скачивание. Пожалуйста ожидайте.');
            Ax(
                {
                    url: '/api-py/fulfillment-report/420/report',
                    method: 'POST',
                    responseType: 'blob',
                    data: request
                },
                (data:any) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    let name = 'Сверка с 4-20 Загрузчик'
                    if (this.source.code === 0) {
                        name = 'Сверка с 4-20 Интеграция'
                    }
                    link.setAttribute('download', name + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    that.loading = false
                },
                (error) => {
                    that.loading = false
                    this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                }
            );
        } catch (error) {
            that.loading = false
            this.makeToast('danger', 'Предупреждение', 'Необходимо заполнить все обязательные фильтры!');
        }
    }

    private  createTree(data: any[], cutness: number, funcDict: any, guDict: any, spfDict: any) {
    const dataDict: any = {};
    const sumVals: string[] = ['obaz', 'plat', 'plg', 'sum_oblig', 'sum_payment'];

    for (const datum of data) {
        const d:any = {...datum, 'collapse': true};
        const abp: any = d['abp'];
        const gu: any = d['gu'];
        const prg: any = d['prg'];
        const ppr: any = d['ppr'];
        const spf: any = d['spf'];
        let abpNode:any = {};
        let guNode:any = null
        let prgNode:any = null
        let pprNode:any = null
        let spfNode:any = null
        if (!(abp in dataDict)) {
            abpNode = {...d};
            abpNode['name_ru'] = '';
            abpNode['name_kk'] = '';
            // abpNode['dict_child_list'] = {};
            abpNode['child_list'] = [];
            dataDict[abp] = abpNode;
        } else {
            for (const val of sumVals) {
                if ((d[val] === null && abpNode[val] === null) || d[val] === null) {
                    continue;
                }
                if (abpNode[val] === null) {
                    abpNode[val] = 0;
                }
                abpNode[val] += d[val];
            }
        }

        if (cutness > 0) {
            guNode = abpNode.child_list.find((node: any) => node.gu === gu)
            if (!guNode) {
                guNode = {...d};
                guNode['name_ru'] = '';
                guNode['name_kk'] = '';
                // guNode['dict_child_list'] = {};
                guNode['child_list'] = [];
                // abpNode['dict_child_list'][gu] = guNode;
                abpNode['child_list'].push(guNode);
            } else {
                for (const val of sumVals) {
                    if ((d[val] === null && guNode[val] === null) || d[val] === null) {
                        continue;
                    }
                    if (guNode[val] === null) {
                        guNode[val] = 0;
                    }
                    guNode[val] += d[val];
                }
            }
        }

        if (cutness > 1) {
            prgNode = guNode.child_list.find((node: any) => node.prg === prg)
            if (!prgNode) {
                prgNode = {...d};
                prgNode['name_ru'] = '';
                prgNode['name_kk'] = '';
                // prgNode['dict_child_list'] = {};
                prgNode['child_list'] = [];
                // guNode['dict_child_list'][prg] = prgNode;
                guNode['child_list'].push(prgNode);
            } else {
                for (const val of sumVals) {
                    if ((d[val] === null && prgNode[val] === null) || d[val] === null) {
                        continue;
                    }
                    if (prgNode[val] === null) {
                        prgNode[val] = 0;
                    }
                    prgNode[val] += d[val];
                }
            }

            pprNode = prgNode.child_list.find((node: any) => node.ppr === ppr)
            if (!pprNode) {
                pprNode = {...d};
                pprNode['name_ru'] = '';
                pprNode['name_kk'] = '';
                // pprNode['dict_child_list'] = {};
                pprNode['child_list'] = [];
                // prgNode['dict_child_list'][ppr] = pprNode;
                prgNode['child_list'].push(pprNode);
            } else {
                for (const val of sumVals) {
                    if ((d[val] === null && pprNode[val] === null) || d[val] === null) {
                        continue;
                    }
                    if (pprNode[val] === null) {
                        pprNode[val] = 0;
                    }
                    pprNode[val] += d[val];
                }
            }

            spfNode = pprNode.child_list.find((node: any) => node.spf === spf)
            if (!spfNode) {
                spfNode = {...d};
                // spfNode['dict_child_list'] = {};
                spfNode['child_list'] = [];
                // pprNode['dict_child_list'][spf] = spfNode;
                pprNode['child_list'].push(spfNode);
            } else {
                for (const val of sumVals) {
                    if ((d[val] === null && spfNode[val] === null) || d[val] === null) {
                        continue;
                    }
                    if (spfNode[val] === null) {
                        spfNode[val] = 0;
                    }
                    spfNode[val] += d[val];
                }
            }
        }

        if (funcDict) {
            if (abp in funcDict) {
                if (!abpNode['name_ru']) {
                    abpNode['name_ru'] = funcDict[abp]['name_ru'];
                    abpNode['name_kk'] = funcDict[abp]['name_kk'];
                }
                if (prg in funcDict[abp]['child_list'] && cutness > 1) {
                    const prgFunc = funcDict[abp]['child_list'][prg];
                    if (!prgNode['name_ru'] && prgFunc) {
                        prgNode['name_ru'] = prgFunc['name_ru'];
                        prgNode['name_kk'] = prgFunc['name_kk'];
                    }
                    if (ppr in prgFunc['child_list']) {
                        const pprFunc = prgFunc['child_list'][ppr];
                        if (!pprNode['name_ru'] && pprFunc) {
                            pprNode['name_ru'] = pprFunc['name_ru'];
                            pprNode['name_kk'] = pprFunc['name_kk'];
                        }
                    }
                }
            }
        }

        if (guDict && cutness > 0) {
            if (gu in guDict) {
                guNode['name_ru'] = guDict[gu]['name_ru'];
                guNode['name_kk'] = guDict[gu]['name_kk'];
            }
        }

        if (spfDict && cutness > 1) {
            if (spf in spfDict) {
                spfNode['name_ru'] = spfDict[spf]['name_ru'];
                spfNode['name_kk'] = spfDict[spf]['name_kk'];
            }
        }
    }

    return Object.values(dataDict);
}

    private async loadReferences(spfObj:any) {
        this.currRefObject = spfObj
        const that = this
        let dif1:any = this.getBg(1, spfObj, 0)
        let dif2:any = this.getBg(1, spfObj, 1)
        dif1 = dif1.replace(/\s+/g, '').replace(',', '.')
        dif2 = dif2.replace(/\s+/g, '').replace(',', '.')

        dif1 = parseFloat(dif1)
        dif2 = parseFloat(dif2)
        if (typeof dif1 !== 'number') {
            dif1 = 0;
        }
        if (typeof dif2 !== 'number') {
            dif2 = 0;
        }
        const difList:number[] = []
        if (dif1) difList.push(dif1)
        if (dif2) difList.push(dif2)
        const req:any = {
            year: this.date_to.getFullYear(),
            date: this.formatedDate(this.date_to),
            gu: spfObj.gu,
            prg: spfObj.prg,
            ppr: spfObj.ppr,
            spf: spfObj.spf,
            dif: difList
        }
        await axios.post('/api-py/fulfillment-report/420/error-references', req).then(resp => {
            if (resp.status === 200) {
                that.referenceList = resp.data.ref_list;
                let ic = 0
                for (const r of that.referenceList) {
                    r.ref_key = ic
                    ic++;
                }
                that.currentRefernceStatus = resp.data.query_index;
                that.modalForm = true;
            }
        }).catch(e => {

        })
    }

    private getRequestType(row:any) {
        if (row) {
            const request_obj = this.requestTypeOptions[row.requestType]
            if (request_obj) return request_obj.localText
            else return row.requestType
        } else {
            return ''
        }
    }

    private getDateOfAgreement(row:any) {
        if (row.statusList && row.statusList.length) {
            let latestStatus26Data = null;
            let maxDate = -1;

            for (const item of row.statusList) {
                if (item.status === 26 && item.create_date > maxDate) {
                    latestStatus26Data = item;
                    maxDate = item.create_date;
                }
            }
            if (latestStatus26Data) return latestStatus26Data.create_date
        }
        if (row.status && row.status.hasOwnProperty('status')) return row.status.create_date
        return new Date().getMilliseconds()
    }



    private makeToast(variant:any, title:any, tostbody:any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }
}
