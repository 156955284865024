<template>
    <div>
        <div class="table-container">
            <!-- хлебные крошки-->
            <div class="filter-breadcrumb">
                <span class="item-breadcrumb" v-if="reg !== null && reg_name !== null" >{{ reg }} - {{ reg_name }}</span>
                <span class="item-breadcrumb" >{{ 'Дата' }} - {{ dateTo}}</span>
                <!-- <span class="item-breadcrumb" v-if="year !== null" >{{'Год'}} - {{ year }}</span> -->
                <!-- <span class="item-breadcrumb" v-if="qvartal !== null" >{{ qvartal}}</span> -->
            </div>
        </div>
                    <div class="table-container">
                        <b-table
                            :fields="tableFields"
                            :items="version === 0 ? unReport : overReport"
                            responsive="true"
                            bordered
                            head-variant="light"
                            no-border-collapse
                            sticky-header="true"
                        >
                            <template #thead-top="">
                                <b-tr>
                                    <b-th colspan="4"></b-th>
                                    <b-th colspan="4" class="text-center">{{ rangeMonth }}</b-th>
                                    <b-th colspan="4" class="text-center">{{ selectedMonth }}</b-th>
                                </b-tr>
                            </template>
                            <template #top-row="data">
                                <td v-for="(field, c) in data.fields.filter(v => ((v.thClass !== 'dhNone') && (v.label !== '')))"
                                    :key="c + field" class="td-numbering">
                                    <div class="text-center">
                                        {{ c + 1 }}
                                    </div>
                                </td>
                            </template>
                            <template #cell()="data">
                                <div v-if="round" class="text-left">{{ parseFloat(data.value).toFixed(5) }}</div>
                                <div v-else class="text-left">{{ $n(parseFloat(data.value).toFixed(1)) }}</div>
                            </template>
                            <template #cell(num)="data">
                                {{ data.index + 1 }}
                            </template>
                            <template #cell(dspk)="data">
                                {{ data.item.dspk.includes('00000') ? data.item.dspk.split('')[0] : data.item.dspk }}
                            </template>
                            <template #cell(name_ru)="data">
                                <div class="text-left">
                                    {{ data.item.name_ru }}
                                </div>
                            </template>
                            <template #cell(delta2)="data">
                                <div class="text-left">
                                    {{ $n(Math.round(data.value * 10) / 10) }}
                                </div>
                            </template>
                            <template #cell(plan_year)="data">
                                <div class="text-left">
                                    {{ $n(Math.round(data.value * 10) / 10) }}
                                </div>
                            </template>
                            <template #cell(plan)="data">
                                <div class="text-left">
                                    {{ $n(Math.round(data.value * 10) / 10) }}
                                </div>
                            </template> 
                            <template #cell(plan_mon)="data">
                                <div class="text-left">
                                    {{ $n(Math.round(data.value * 10) / 10) }}
                                </div>
                            </template> 
                            <template #cell(mon_delta2)="data">
                                <div class="text-left">
                                    {{ $n(Math.round(data.value * 10) / 10) }}
                                </div>
                            </template>
                        </b-table>
                    </div>


        <div class="actions-tab in-tab">
            <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                <template #button-content >
                    <div class="btn-download">
                        <div>
                                        <span class="left">
                                            <i class="icon icon-download"></i> Скачать
                                        </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-item @click="downloadRep(curReport)">{{repName}}</b-dropdown-item>
            </b-dropdown>
        </div>
        <div>
            <loading
                :active="loading"
                is-full-screen
                spinner="bar-fade-scale"
                color="#6495ED"
            />
        </div>
        </div>
</template>
<script>
import {Ax} from '@/utils';
import moment from 'moment';
import VueElementLoading from "vue-element-loading";

export default {
    name: "application-form-4",
    components: {'loading': VueElementLoading},
    data() {
        return {
            obl: null,
            access_level: 1,
            version: 0,
            listReg: [],
            curParams: {},
            reg: null,
            reg_name: null,
            qvartal: null,
            year: null,
            dateFrom: null,
            modalForm: false,
            firstDateFrom: `${new Date().getFullYear()}-${new Date().getMonth() + 1}` + '-01',
            firstDateTo: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`,
            curDate: null,
            dateTo: null,
            isLoad: false,
            isOpen: false,
            isNew: false,
            repCode: 'PR4',
            repName: 'Приложение_4',
            loading: false,
            tableFields: [
                {
                    key: 'num',
                    label: '№'
                },
                {
                    key: 'dspk',
                    label: '№ категории/код БК'
                },
                {
                    key: 'name_ru',
                    label: 'Наименование доходов'
                },
                {
                    key: 'plan_year',
                    label: 'Скорректированный план на год'
                },
                {
                    key: 'plan',
                    label: 'Скорректированный план'
                },
                {
                    key: 'fact',
                    label: 'Фактические поступления'
                },
                {
                    key: 'delta1',
                    label: 'Отклонение'
                },
                {
                    key: 'delta2',
                    label: '% исполнения'
                },
               {
                    key: 'plan_mon',
                    label: 'Скорректированный план'
                },
                {
                    key: 'fact_mon',
                    label: 'Фактические поступления'
                },
                {
                    key: 'mon_delta1',
                    label: 'Отклонение'
                },
                {
                    key: 'mon_delta2',
                    label: '% исполнения'
                },
            ],
            unReport: [],
            curTab: 0,
            changeRow: false,
            curReport: [],
            curRegion: 0,
            modalDataIsSavable: null,
            monthes: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь',
                'декабрь'],
            selectedMonth: '',
            rangeMonth: '',
            round: false
        };
    },

    async mounted() {
        await this.getAccessLevel();
    },
    created() {
        this.$on('loadApplication4', (reg, reg_name, period, date_to, qvartal, year, obl, round) => {
            this.reportOpen(reg, period, date_to, qvartal, year, obl, round);
            this.reg = reg;
            this.year = year;
            this.qvartal = qvartal;
            this.reg_name = reg_name;
            this.round = round;
            this.dateTo = moment(date_to).format('YYYY-MM-DD');
        });
    },
    methods: {
        async getAccessLevel() {
            try {
                const response = await fetch(`/api-py/user-modules/${this.$store.getters.user_uuid}/005.003.006`);

                const modules_access = await response.json();
                if (modules_access.length > 1) {
                    this.makeToast("danger", "Ошибка", "У пользователя несколько записей")
                }
                if (modules_access.length === 0) {
                    this.access_level = 1;
                } else {
                    for (const ma of modules_access) {
                        this.access_level = ma.access_level;
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса load_user_access_level', error.toString());
            }
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        },
        async reportOpen(reg, period, date_to, qvartal, year, obl, round) {
            this.loading = true;
            this.unReport = [];
            this.curParams = {
                region: reg,
                period: period,
                date_to: date_to,
                qvartal: qvartal,
                year: year,
                report_id: this.report_id,
                form: this.repCode,
                obl: obl,
                round: round
            };
            if (date_to.getDate() === 1) {
                if (date_to.getMonth() === 0) {
                    this.selectedMonth = this.monthes[this.monthes.length - 1]
                    this.rangeMonth = `Январь - ${this.selectedMonth} ${date_to.getFullYear() - 1} г.`
                }
                else {
                    this.selectedMonth = this.monthes[date_to.getMonth() - 1]
                    this.rangeMonth = `Январь - ${this.selectedMonth} ${date_to.getFullYear()} г.`
                }
            } else {
                this.selectedMonth = this.monthes[date_to.getMonth()]
                this.rangeMonth = `Январь - ${this.selectedMonth} ${date_to.getFullYear()} г.`
            }
            Ax(
                {
                    url: `/api-py/get-fulldata-4/` + JSON.stringify(this.curParams),
                    method: 'GET'
                },
                (data) => {
                    if (data.unReport === 0){
                        this.makeToast('info', 'Внимание', 'Данных по таким параметрам нет.');
                    } else {
                        const group_titles = {
                            '1': 'Налоговые поступления',
                            '2': 'Неналоговые поступления',
                            '3': 'Поступления от продажи основного капитала',
                            '4': 'Поступления трансфертов',
                            '5': 'Погашение бюджетных кредитов',
                            '6': 'Поступления от продажи финансовых активов государства',
                            '7': 'Поступления займов'
                        }
                        for (let i = 1; i < 8; i++) {
                            const idx = String(i)
                            const first = data.unReport.filter((a) => a.dspk.split('')[0] === idx);
                            if (first.length > 0) {
                                const d = {
                                    dspk: idx + '00000',
                                    name_ru: group_titles[idx],
                                    fact: 0,
                                    plan: 0,
                                    delta1: 0,
                                    delta2: 0,
                                    fact_mon: 0,
                                    plan_mon: 0,
                                    mon_delta1: 0,
                                    mon_delta2: 0,
                                    plan_year: 0,
                                    _rowVariant: 'secondary'
                                }
                                for (const item of first) {
                                    d.fact += item.fact;
                                    d.plan += item.plan;
                                    d.fact_mon += item.fact_mon;
                                    d.plan_mon += item.plan_mon;
                                    d.plan_year += item.plan_year;
                                }
                                d.delta1 = d.fact - d.plan;
                                if (d.fact > 0 && d.plan > 0) {
                                    d.delta2 = Math.round((d.fact / d.plan) * 1000) / 10;
                                }
                                d.mon_delta1 = d.fact_mon - d.plan_mon;
                                if (d.fact_mon > 0 && d.plan_mon > 0) {
                                    d.mon_delta2 = Math.round((d.fact_mon / d.plan_mon) * 1000) / 10;
                                }
                                this.unReport.push(d)
                            }
                        }
                    }
                    this.unReport = this.unReport.concat(data.unReport);
                    for (const item of this.unReport) {
                        if (!round) {
                            item.plan = Math.round(item.plan * 10) / 10
                            item.fact = Math.round(item.fact * 10) / 10
                            item.fact_mon = Math.round(item.fact_mon * 10) / 10
                            item.plan_mon = Math.round(item.plan_mon * 10) / 10
                            item.plan_year = Math.round(item.plan_year * 10) / 10
                        } else {
                            item.plan = Math.round(item.plan * 100000) / 100000
                            item.fact = Math.round(item.fact * 100000) / 100000
                            item.fact_mon = Math.round(item.fact_mon * 100000) / 100000
                            item.plan_mon = Math.round(item.plan_mon * 100000) / 100000
                            item.plan_year = Math.round(item.plan_year * 100000) / 100000
                        }
                    }
                    this.unReport.sort((a, b) => a.dspk - b.dspk);
                    this.curTab = 0;
                    this.loading = false;
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса', error.toString());
                    this.loading = false;
                }
            )
        },
        toOpen() {
            this.isOpen = !this.isOpen;
            this.$emit('repOpen', this.isOpen);
        },
        downloadRep() {
            try {
                this.makeToast('info', 'Внимание!', 'Скоро начнется скачивание. Пожалуйста ожидайте.');
                this.isLoad = true
                Ax(
                    {
                        url: '/api-py/fulfill_report4/' + JSON.stringify(this.curParams),
                        method: 'POST',
                        responseType: 'blob'
                    },
                    (data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Приложение 4 - Поступления по 7-ми категориям.xlsx');
                        document.body.appendChild(link);
                        link.click();
                    },
                    (error) => {
                        this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                    }
                );
            } catch (error) {
                console.log(error)
                this.makeToast('danger', 'Предупреждение', 'Необходимо заполнить все обязательные фильтры!');
            } finally {
                this.isLoad = false
            }
        }
    }
}
</script>

<style scoped>
.td-numbering{
    padding: 3px 10px!important;
    text-align: center;
    font-size: 0.7rem !important;
    font-weight: 400!important;
    color: #6087A0 !important;
    background-color: #F7F9FC;
}
</style>