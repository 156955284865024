<template>
    <div class="inner-container treasury-card">
        <div class="actions-tab in-tab">
            <b-dropdown variant="link" left toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <div class="btn-download">
                        <div>
                            <span class="left">
                                <i class="icon icon-download"></i> Скачать
                            </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-item @click="downloadRep('211')" v-if="selectedCodeReport && selectedCodeReport.value === 'FORM_211'">Форма 2-11</b-dropdown-item>
                <b-dropdown-item @click="downloadRep('219')" v-if="selectedCodeReport && selectedCodeReport.value === 'FORM_219'">Форма 2-19</b-dropdown-item>
                <b-dropdown-item @click="downloadRep('243')" v-if="selectedCodeReport && selectedCodeReport.value === 'FORM_243'">Форма 2-43</b-dropdown-item>
                <b-dropdown-item @click="downloadRep('243_2')" v-if="selectedCodeReport && selectedCodeReport.value === 'FORM_243'">Выписка по форме 2-43</b-dropdown-item>
                <b-dropdown-item @click="downloadRep('409')" v-if="selectedCodeReport && selectedCodeReport.value === 'FORM_409'">Форма 4-09</b-dropdown-item>
                <b-dropdown-item @click="downloadRep('420')" v-if="selectedCodeReport && selectedCodeReport.value === 'FORM_420'">Форма 4-20</b-dropdown-item>
                <b-dropdown-item @click="downloadRep('534')" v-if="selectedCodeReport && selectedCodeReport.value === 'FORM_534'">Форма 5-34</b-dropdown-item>
                <b-dropdown-item @click="downloadRep('542')" v-if="selectedCodeReport && selectedCodeReport.value === 'FORM_542'">Форма 5-42</b-dropdown-item>
                <b-dropdown-item @click="downloadRep('552')" v-if="selectedCodeReport && selectedCodeReport.value === 'FORM_552'">Форма 5-52</b-dropdown-item>

            </b-dropdown>
            <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <div class="btn-download">
                        <div>
                        <span class="left">
                            <i class="icon icon-info"></i>
                        </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-text>
                    <div class="info-button">
                        <span @click="infoCostForm">Инструкция<i class="icon icon-folder"></i></span>
                    </div>
                    <div class="info-button">
                    <span class="font-italic" @click="openVideoOnNewTab">Видеоинструкция
                        <i class="icon icon-video"></i>
                    </span>
                    </div>
                    <div class="info-text">
                        <p>
                            <span class="red bold">Режим «Казначейские формы»</span><br>
                            Данный режим предназначен для формирования Казначейских форм: 2-11, 2-19,
                            2-43, 4-09, 4-20, 5-34, 5-42, 5-52 путем интеграционного взаимодействия с информационной системой
                            Комитета Казначейства (ИС КК).</p>
                    </div>
                </b-dropdown-text>
            </b-dropdown>
        </div>
        <div class="form-container">
            <b-row class="bb-row py-2">
                <b-col cols="12" md="6">
                    <b-form-group label="Наименование формы" label-cols-md="3" class="bolded-label">
                        <multiselect
                            track-by="value"
                            label="name_ru"
                            placeholder="Выберите"
                            :options="code_reports"
                            v-model="selectedCodeReport"
                            :searchable="true"
                            :allow-empty="false"
                            :show-labels="false"
                        >
                        </multiselect>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-progress
                variant="success"
                v-show="progress <= 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
            ></b-progress>
            <treasury-form-211 @onDownload="onDownload" ref="trf211" v-if="selectedCodeReport && selectedCodeReport.value === 'FORM_211'" />
            <treasury-form-219 @onDownload="onDownload" ref="trf219" v-if="selectedCodeReport && selectedCodeReport.value === 'FORM_219'" />
            <treasury-form-243 @onDownload="onDownload" ref="trf243" v-if="selectedCodeReport && selectedCodeReport.value === 'FORM_243'" />
            <treasury-form-409 @onDownload="onDownload" ref="trf409" v-if="selectedCodeReport && selectedCodeReport.value === 'FORM_409'"/>
            <treasury-form-420 @onDownload="onDownload" ref="trf420" v-if="selectedCodeReport && selectedCodeReport.value === 'FORM_420'"/>
            <treasury-form-534 @onDownload="onDownload" ref="trf534" v-if="selectedCodeReport && selectedCodeReport.value === 'FORM_534'"/>
            <treasury-form-542 @onDownload="onDownload" ref="trf542" v-if="selectedCodeReport && selectedCodeReport.value === 'FORM_542'" />
            <treasury-form-552 @onDownload="onDownload" ref="trf552" v-if="selectedCodeReport && selectedCodeReport.value === 'FORM_552'" />
        </div>
    </div>
</template>

<script>
import treasuryForm211 from "@/modules/budget/treasury-forms/treasury-form-211";
import treasuryForm219 from "@/modules/budget/treasury-forms/treasury-form-219";
import treasuryForm243 from "@/modules/budget/treasury-forms/treasury-form-243";
import treasuryForm409 from "@/modules/budget/treasury-forms/treasury-form-409";
import treasuryForm420 from "@/modules/budget/treasury-forms/treasury-form-420";
import treasuryForm534 from "@/modules/budget/treasury-forms/treasury-form-534";
import treasuryForm542 from "@/modules/budget/treasury-forms/treasury-form-542";
import treasuryForm552 from "@/modules/budget/treasury-forms/treasury-form-552";
import axios from "axios";


export default {
    name: "treasury-card",
    components: {treasuryForm409, treasuryForm211, treasuryForm219, treasuryForm243, treasuryForm534, treasuryForm542, treasuryForm552, treasuryForm420},
    data() {
        return {
            code_reports: [
                {name_ru: '2-11 «Отчет о распределении поступлений по дням»', value: 'FORM_211'},
                {name_ru: '2-19 «Ведомость ежедневного распределения поступлений»', value: 'FORM_219'},
                {name_ru: '2-43 «Поступления по коду классификации бюджета»', value: 'FORM_243'},
                {name_ru: '4-09 «Детали периодических обязательств»', value: 'FORM_409'},
                {name_ru: '4-20 «Сводный отчет по расходам»', value: 'FORM_420'},
                {name_ru: '5-34 «Отчет о состоянии КСН соответствующего бюджета»', value: 'FORM_534'},
                {name_ru: '5-42 «Расходы за день»', value: 'FORM_542'},
                {name_ru: '5-52 «Сводный отчет по расходам»', value: 'FORM_552'},
            ],
            selectedCodeReport: null,
            isLoad: false,
            progress: 0
        }
    },
    methods: {
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async downloadRep(rep) {

            try {

                this.progress = 30
                this[`downloadRep${rep}`]()
            } catch (e) {
                console.log('error happend', e)
                this.progress = 0
            }
        },
        async onDownload(p) {
            this.progress = p;
            if (p === 100) {
                await this.sleep(2000)
                this.progress = 0
            }
        },
        downloadRep211() {
            if (typeof this.$refs.trf211 !== 'undefined') {
                const trf = this.$refs.trf211;
                trf.downloadRep();
            }
        },
        downloadRep219() {
            if (typeof this.$refs.trf219 !== 'undefined') {
                const trf = this.$refs.trf219;
                trf.downloadRep();
            }
        },
        downloadRep243() {
            if (typeof this.$refs.trf243 !== 'undefined') {
                const trf = this.$refs.trf243;
                trf.downloadRep();
            }
        },
        downloadRep243_2() {
            if (typeof this.$refs.trf243 !== 'undefined') {
                const trf = this.$refs.trf243;
                trf.downloadRepStatement();
            }
        },
        downloadRep409() {
            if (typeof this.$refs.trf409 !== 'undefined') {
                const trf = this.$refs.trf409;
                trf.downloadRep();
            }
        },
        downloadRep420() {
            if (typeof this.$refs.trf420 !== 'undefined') {
                const trf = this.$refs.trf420;
                trf.downloadRep();
            }
        },
        downloadRep534() {
            if (typeof this.$refs.trf534 !== 'undefined') {
                const trf = this.$refs.trf534;
                trf.downloadRep();
            }
        },
        downloadRep542() {
            if (typeof this.$refs.trf542 !== 'undefined') {
                const trf = this.$refs.trf542;
                trf.downloadRep();
            }
        },
        downloadRep552() {
            if (typeof this.$refs.trf552 !== 'undefined') {
                const trf = this.$refs.trf552;
                trf.downloadRep();
            }
        },

        async infoCostForm() {
            if (this.isLoad) {
                this.makeToast('info', 'Внимание', 'Скоро начнется скачивание. Пожалуйста ожидайте.');
            } else {
                this.isLoad = true;
                await axios({
                    method: 'post',
                    url: '/api-py/get-info/28',
                    responseType: 'blob',
                    data: null
                })
                    .then((response) => response.data)
                    .then((data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        const titleOfFile = '2022-01-19 Инструкция Казначейские формы';
                        link.setAttribute('download', titleOfFile + '.pdf');// or any other extension
                        document.body.appendChild(link);
                        link.click();
                        // handle success
                    })
                    .catch((error) => {
                        console.log(error);
                        this.makeToast('danger', 'Ошибка запроса infoCostForm()', error.toString());
                    })
                    .finally(() => {
                        this.isLoad = false;
                    });
            }
        },
        openVideoOnNewTab() {
            window.open('https://www.youtube.com/watch?v=HHZv7tKh3cM&list=PL4jwFLRAXDike08HbW8D0DCoto5a5TgP1');
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }
    }
};
</script>

<style scoped>

</style>