import moment from "moment"

// #region number formatting
export const formatDigitsGrouping = (number: number | string): string => {
    const fullNumberString: string = number + ''

    let decimalIndex = fullNumberString.indexOf(',')
    if (decimalIndex === -1) {
        decimalIndex = fullNumberString.indexOf('.') 
    }
    if (decimalIndex === -1) {
        decimalIndex = fullNumberString.length
    }

    const decimalPart = fullNumberString.substring(decimalIndex, fullNumberString.length)
    const wholePart = fullNumberString.substring(0, decimalIndex)

    const groupedWholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    return groupedWholePart + decimalPart
}

export const formatMaxFloat = (number: number | string): string => {
    let value = number + ''
    let minusSign = ''
    if (value?.charAt(0) === '-') {
        minusSign = '-'
    }

    const maximumFloatLength = 2
    value = value.replace(/[^\d,. ]/g, "")
    let until = value.length
    if (value.indexOf(',') !== -1) {
        until = value.indexOf(',') + maximumFloatLength + 1
    } else if (value.indexOf('.') !== -1) {
        until = value.indexOf('.') + maximumFloatLength + 1
    }
    value = value.substring(0, until)

    if (value.endsWith('.00')) {
        value = value.substring(0, value.indexOf('.'))
    }

    return minusSign + value
}

export const rounding = (number: number | string): string => {
    let value = number + ''
    let minusSign = ''
    if (value && value?.charAt(0) === '-') {
        minusSign = '-'
    }
    value = value.replace(/[^\d,. ]/g, "")
    let thirdIndex
    if (value.indexOf(',') !== -1) {
        thirdIndex = value.indexOf(',') + 3
    } else if (value.indexOf('.') !== -1) {
        thirdIndex = value.indexOf('.') + 3
    }
    if (thirdIndex) {
        const thirdNumber = parseInt(value[thirdIndex])
        if (thirdNumber >= 5 && thirdNumber <= 9) {
            value = (parseFloat(value) + 0.01).toString()
        }
    }

    return minusSign + value
}

export const formatNumberCombined = (number: number | string): string => {
    return formatDigitsGrouping(formatMaxFloat(rounding(number)))
}

export const padLeadingZeros = (number: number | string, size: number): string => {
    let s = String(number)
    while (s.length < size) {
        s = '0' + s;
    }
    return s
}
// #endregion

export const getLastDateOfQuarter = (year: number, quarter: number): string => {
    if (quarter < 1 || quarter > 4) {
        throw new Error("Quarter must be between 1 and 4")
    }

    // Map quarter to the last month of that quarter
    const map = {
        1: 3, // Q1 -> March
        2: 6, // Q2 -> June
        3: 9, // Q3 -> September
        4: 12 // Q4 -> December
    }
    // @ts-ignore
    const lastMonthOfQuarter: number = map[quarter]

    return moment([year, lastMonthOfQuarter-1, 1]).endOf('month').format('YYYY-MM-DD')
}

export const getLastDateOfMonth = (year: number, month: number): string => {
    if (month < 1 || month > 12) {
        throw new Error("Month must be between 1 and 12")
    }

    return moment([year, month-1, 1]).endOf('month').format('YYYY-MM-DD')
}

export const makeToast = (vue: any, message: string, title: string, variant: 'success' | 'danger' | 'info' | '') => {
    vue.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
    })
}
