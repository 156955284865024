







































































































































































import {Vue, Component} from 'vue-property-decorator';
import VueElementLoading from "vue-element-loading";
import axios from "axios";
import {Ax} from "@/utils";

@Component({
    components: {
        'loading': VueElementLoading
    },
    filters: {
        formatDate: function (value:any) {
            if (value) {
                const date = new Date(value);
                return date.toLocaleDateString();
            }
            return null;
        }
    }
})
export default class ReportForm127 extends Vue{
    public obl:string = ''
    public reg:any = null;
    public year:any = new Date().getFullYear();
    public month:any = new Date().getMonth()
    public cutness:any = null;
    public user_id: any = '';
    public showOnlyErrors:boolean = false;

    public loading:boolean = false;

    public tableData:any[] = [];
    public treeData:any[] = []
    public regionsDict:any = {}
    public katDict:any = {}
    public numValues:string[] = ['god_ea', 'mes_ea', 'god127', 'mes127', 'dev_god', 'dev_mes']

    public get totalTreeCollumn() {
        if (this.cutness.code === 0) return 1
        if (this.cutness.code === 1) return 4
        if (this.cutness.code === 2) return 5
        return 1
    }

    public collapse: boolean = true;

    public clkCollapseAll(data: any, collapse = true) {
        this.$set(data, 'collapse', collapse);
        if ('child_list' in data && data.child_list && data.child_list.length) {
            for (const el of data.child_list) {
                this.clkCollapseAll(el, collapse)
            }
        }
    }

    public clkCollapse(reg: any = null, kat: any = null, cls: any = null, pcl: any = null, collapse: boolean = false) {
        if (
            (reg !== null)
        ) {
            let obj = this.treeData[reg]
            if (kat !== null) {
                obj = obj.child_list[kat];
            }
            if (cls !== null) {
                obj = obj.child_list[cls]
            }
            if (pcl !== null) {
                obj = obj.child_list[pcl]
            }
            if (collapse) {
                this.clkCollapseAll(obj, collapse)
            }
            this.$set(obj, 'collapse', collapse);
        } else {
            this.collapse = collapse;
            for (const el of this.cutness.code === 2? this.treeData: this.treeData[0].child_list) {
                this.clkCollapseAll(el, collapse)
            }

        }
    }
    async created() {
        try {
            await fetch('/api-py/get-budget-obl/' + this.$store.state._instanceCode)
                .then(response => response.json())
                .then(json => {
                    this.obl = json.obl;
                });
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
        }
        const that = this
        Object.defineProperty(this.total, 'child_list', {
            get: function () {
                return that.treeData
            }
        })
        this.calcFields(this.total)
        this.$on('reportForm127', (region:any, year:number, month:number, cutness:any, uuid:string, showOnlyErrors:boolean) => {
            this.tableData = [];
            this.treeData  = []
            this.cutness = null;
            this.reg = null;
            this.user_id = '';
            this.year = year
            this.month = month
            this.loadRegionDict()
            this.loadData(region, year, month, cutness, uuid, showOnlyErrors);
            this.cutness = cutness;
            this.reg = region;
            this.user_id = uuid
            this.showOnlyErrors = showOnlyErrors
        });
    }
    public async loadData(region:any, year:number, month:number, cutness:any, uuid:string, showOnlyErrors:boolean) {
        this.loading = true
        const formatedDate = this.formatedDate(year, month)
        const that = this
        const request = {
            'cutness': cutness.code,
            'region': region.code,
            'year': year,
            'month': month,
            'repdate': formatedDate,
            'user_id': uuid,
            'locale': this.$i18n.locale,
            'showOnlyErrors': showOnlyErrors
        }
        await axios.post('/api-py/fulfillment-report/127/data', request).then(resp => {
            if (resp.status === 200) {
                that.tableData = resp.data.list
                that.katDict = resp.data.funcDict
                that.treeData = that.createTree(that.tableData, that.katDict)
                that.loading = false
            }
        })
        that.loading = false
    }

    public downloadRep() {
        this.loading = true
        const formatedDate = this.formatedDate(this.year, this.month)
        const that = this
        const request = {
            'cutness': this.cutness.code,
            'region': this.reg.code,
            'year': this.year,
            'month': this.month,
            'repdate': formatedDate,
            'user_id': this.user_id,
            'locale': this.$i18n.locale,
            'showOnlyErrors': this.showOnlyErrors
        }
        try {
            this.makeToast('info', 'Внимание!', 'Скоро начнется скачивание. Пожалуйста ожидайте.');
            Ax(
                {
                    url: '/api-py/fulfillment-report/127/report',
                    method: 'POST',
                    responseType: 'blob',
                    data: request
                },
                (data:any) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    const cutKey = `cutness_${that.cutness.code}`;
                    const cutText = that.tt(cutKey)
                    const nameText = this.tt('name_report_127')
                    const name = `${nameText} (${that.reg.code} - ${cutText}, ${formatedDate})`
                    link.setAttribute('download', name + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    that.loading = false
                },
                (error) => {
                    that.loading = false
                    this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                }
            );
        } catch (error) {
            that.loading = false
            this.makeToast('danger', 'Предупреждение', 'Необходимо заполнить все обязательные фильтры!');
        }
    }

    public async loadRegionDict() {
        await axios.get(`/api-py/fulfillment-report/region-dicts/${this.obl}/${this.formatedDate(this.year, this.month)}`).then(resp => {
            if (resp.status === 200) {
                for (const r of resp.data) {
                    this.regionsDict[r.code] = r
                }
            }
        })
    }


    public total:any = {}
    public reSum(item:any, field:string) {
        let sum = 0;
        if (field[0] === '_') {
            field = field.replace('_', '');
        }
        for (const ch1 of item.child_list) {
            sum += ch1[field]
        }
        return sum

    }
    public calcFields(item:any, sum:boolean = true) {
        const that = this
        if (sum) {
            for (const val of this.numValues) {
                Object.defineProperty(item, val, {
                    get: function () {
                        return that.reSum(item, `_${val}`)
                    },
                    set: function (value) {
                        item[`_${val}`] = value;
                    }
                })
            }
        }
    }

    public createTree(tableData:any[], katDict:any = {}) {
        const tree:any = {}
        const nodes:any = {}
        for (const item of tableData) {
            const region = item.region;
            const kat = item.kat;
            const cls = item.cls;
            const pcl = item.pcl;
            const spf = item.spf;

            if (this.cutness.code) {
                let regNode = tree[region]
                if (!regNode) {
                    regNode = Object.assign({
                        collapse: Boolean(this.cutness.code === 2),
                        child_list: []
                    }, item)
                    this.calcFields(regNode)
                    tree[region] = regNode
                }
                let katNode:any = nodes[`${region}_${kat}`]
                if (!katNode) {
                    katNode = Object.assign({
                        collapse: this.collapse,
                        child_list: []
                    }, item)
                    this.calcFields(katNode)
                    regNode.child_list.push(katNode)
                    nodes[`${region}_${kat}`] = katNode
                }
                let clsNode = nodes[`${region}_${kat}_${cls}`]
                if (!clsNode) {
                    clsNode = Object.assign({
                        collapse: this.collapse,
                        child_list: []
                    }, item)
                    this.calcFields(clsNode)
                    katNode.child_list.push(clsNode)
                    nodes[`${region}_${kat}_${cls}`] = clsNode
                }
                let pclNode = nodes[`${region}_${kat}_${cls}_${pcl}`]
                if (!pclNode) {
                    pclNode = Object.assign({
                        collapse: this.collapse,
                        child_list: []
                    }, item)
                    this.calcFields(pclNode)
                    clsNode.child_list.push(pclNode)
                    nodes[`${region}_${kat}_${cls}_${pcl}`] = pclNode
                }
                let spfNode = nodes[`${region}_${kat}_${cls}_${pcl}_${spf}`]
                if (!spfNode) {
                    spfNode = Object.assign({}, item)
                    this.calcFields(clsNode, false)
                    pclNode.child_list.push(spfNode)
                    nodes[`${region}_${kat}_${cls}_${pcl}_${spf}`] = spfNode
                }
                if (this.regionsDict) {
                    if (region in this.regionsDict) {
                        regNode.name_ru = this.regionsDict[region].name_ru;
                        regNode.name_kk = this.regionsDict[region].name_kk;
                    }
                }
                if (katDict) {
                    if (kat in katDict) {
                        katNode.name_ru = katDict[kat].name_ru;
                        katNode.name_kk = katDict[kat].name_kk;
                        if (cls in katDict[kat].child_list) {
                            clsNode.name_ru = katDict[kat].child_list[cls].name_ru
                            clsNode.name_kk = katDict[kat].child_list[cls].name_kk
                            if (pcl in katDict[kat].child_list[cls].child_list) {
                                pclNode.name_ru = katDict[kat].child_list[cls].child_list[pcl].name_ru
                                pclNode.name_kk = katDict[kat].child_list[cls].child_list[pcl].name_kk
                                if (spf in katDict[kat].child_list[cls].child_list[pcl].child_list) {
                                    spfNode.name_ru = katDict[kat].child_list[cls].child_list[pcl].child_list[spf].name_ru
                                    spfNode.name_kk = katDict[kat].child_list[cls].child_list[pcl].child_list[spf].name_kk
                                }
                            }
                        }
                    }
                }




            } else {
                let parNode = tree['000000']
                if (!parNode) {
                    parNode = Object.assign({
                        collapse: Boolean(this.cutness.code === 2),
                        child_list: []
                    }, item)
                    this.calcFields(parNode)
                    tree['000000'] = parNode
                }
                let regionNode:any = nodes[region]
                if (!regionNode) {
                    regionNode = Object.assign({}, item)
                    this.calcFields(regionNode, false)
                    parNode.child_list.push(regionNode)
                    nodes[region] = regionNode
                    if (this.regionsDict) {
                        if (region in this.regionsDict) {
                            regionNode.name_ru = this.regionsDict[region].name_ru;
                            regionNode.name_kk = this.regionsDict[region].name_kk;
                        }
                    }
                }

            }
        }
        return Object.values(tree)
    }


    //modules.common.${val} Where val is common for whole project
    public gt(val:string)  {
        return this.$t(`modules.common.${val}`)
    }
    //modules.budget_execution_monitoring.reports.report_1_27.${val}` where val is belongs only for rep 1-27
    public tt(val:string) {
        return this.$t(`modules.budget_execution_monitoring.reports.report_1_27.${val}`)
    }
    public formatedDate(year: number, month: number) {
        return `${year}-${month+1}-${new Date(year, month + 1, 0).getDate()}`
    }
    public makeToast(variant:string, title:string, tostbody:string) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

}
