import { render, staticRenderFns } from "./treasury-form-534.vue?vue&type=template&id=7637fbe9&scoped=true&"
import script from "./treasury-form-534.vue?vue&type=script&lang=js&"
export * from "./treasury-form-534.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7637fbe9",
  null
  
)

export default component.exports