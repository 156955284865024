




















































































































































import VueElementLoading from "vue-element-loading"
import VueDraggable from 'vuedraggable'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Application2TableRow, Application2TableData, Category, Indicator, TableField } from '@/modules/budget/monitoring/reports-constructor/common/types'
import { formatDigitsGrouping } from '@/modules/budget/monitoring/reports-constructor/common/utils'

@Component({
    components: {
        'draggable': VueDraggable,
        'loading': VueElementLoading
    }
})
export default class Application2 extends Vue {

    @Prop({
        type: Object,
        required: true,
    })
    public tableData!: Application2TableData

    @Prop({
        type: Array,
        required: true,
        default: [],
    })
    private incomes!: Category[]

    @Prop({
        type: Array,
        required: true,
        default: [],
    })
    private propIndicators!: Indicator[]

    public variant: 'UNFULFILLED' | 'OVERFULFILLED' = 'UNFULFILLED'
    public tableFields: TableField[] = []
    public indicators: Indicator[] = []

    public indicatorsNameMap = {
        'plan': 'План поступления на отчетную дату',
        'fact': 'Фактическое поступление на отчетную дату',
        'delta1': 'Отклонение',
        'delta2': '% исполнения плана',
        'reasons': 'Причины отклонения'
    }

    get totalColumnSpan(): number {
        let span = 0
        const indicators = ['plan', 'fact', 'delta1', 'delta2', 'reasons']
        for (const field of this.tableFields) {        
            if (indicators.includes(field.key)) {
                break
            }
            span++
        }
        return span
    }

    get isStandartHierarchy() {
        const activeIncomeCategories = this.incomes.filter(item => item.active)
        return (
            activeIncomeCategories.length === 4
            && activeIncomeCategories[0].key === 'kat'
            && activeIncomeCategories[1].key === 'cls'
            && activeIncomeCategories[2].key === 'pcl'
            && activeIncomeCategories[3].key === 'spf'
        )
    }

    private created() {
        this.indicators = JSON.parse(JSON.stringify(this.propIndicators))
        this.constructTableFields()
    }

    private constructTableFields() {
        this.tableFields = []
        this.tableFields.push({ key: 'index', label: '№', active: true })
        const activeIncomeCategories = this.incomes.filter(item => item.active)
        
        if (this.isStandartHierarchy) {
            this.tableFields.push({ key: 'fullcode', label: 'КБК поступлений', active: true })
        } else {
            activeIncomeCategories.forEach(item => {
                switch (item.key) {
                    case 'kat':
                        this.tableFields.push({ key: 'kat', label: 'Категория', active: true })
                        break
                    case 'cls':
                        this.tableFields.push({ key: 'cls', label: 'Класс', active: true })
                        break
                    case 'pcl':
                        this.tableFields.push({ key: 'pcl', label: 'Подкласс', active: true })
                        break
                    case 'spf':
                        this.tableFields.push({ key: 'spf', label: 'Специфика', active: true })
                        break
                    default:
                        break
                }
            })
        }

        this.tableFields.push({ key: 'nameRu', label: 'Наименование КБК', active: true })

        this.propIndicators.filter(it => it.active).forEach(indicator => {
            switch (indicator.key) {
                case 'plan':
                    this.tableFields.push({ key: 'plan', label: this.indicatorsNameMap.plan, active: true })
                    break
                case 'fact':
                    this.tableFields.push({ key: 'fact', label: this.indicatorsNameMap.fact, active: true })
                    break
                case 'delta1':
                    this.tableFields.push({ key: 'delta1', label: this.indicatorsNameMap.delta1, active: true })
                    break
                case 'delta2':
                    this.tableFields.push({ key: 'delta2', label: this.indicatorsNameMap.delta2, active: true })
                    break
                case 'reasons':
                    this.tableFields.push({ key: 'reasons', label: this.indicatorsNameMap.reasons, active: true })
                    break
                default:
                    break
            }
        })
    }

    public draggableHeader(columnKey: string) {
        return ['plan', 'fact', 'delta1', 'delta2', 'reasons'].includes(columnKey)
    }

    public isTotalColumn(columnKey: string) {
        return ['plan', 'fact', 'delta1'].includes(columnKey)
    }

    public synchronizeIndicatorsOrder() {
        const indicatorKeys = ['plan', 'fact', 'delta1', 'delta2', 'reasons']

        const indicatorsOrder = this.tableFields
            .filter(it => indicatorKeys.includes(it.key))
            .map(it => it.key)

        indicatorKeys.forEach(indicatorKey => {
            if (indicatorsOrder.notIncludes(indicatorKey)) {
                indicatorsOrder.push(indicatorKey)
            }
        })

        const tempIndicators: Indicator[] = []
        indicatorsOrder.forEach(indicatorKey => {
            const IndicatorObject = this.indicators.find(it => it.key === indicatorKey)!
            tempIndicators.push(IndicatorObject)
        })

        this.indicators = tempIndicators
        this.$emit('update-indicators', tempIndicators)
    }

    public synchronizeVisiblityInTable() {
        this.$emit('update-indicators', JSON.parse(JSON.stringify(this.indicators)))

        const tempTableFields: TableField[] = []
        tempTableFields.push({ key: 'index', label: '№', active: true })
        const activeIncomeCategories = this.incomes.filter(item => item.active)

        if (this.isStandartHierarchy) {
            tempTableFields.push({ key: 'fullcode', label: 'КБК поступлений', active: true })
        } else {
            activeIncomeCategories.forEach(item => {
                switch (item.key) {
                    case 'kat':
                        tempTableFields.push({ key: 'kat', label: 'Категория', active: true })
                        break
                    case 'cls':
                        tempTableFields.push({ key: 'cls', label: 'Класс', active: true })
                        break
                    case 'pcl':
                        tempTableFields.push({ key: 'pcl', label: 'Подкласс', active: true })
                        break
                    case 'spf':
                        tempTableFields.push({ key: 'spf', label: 'Специфика', active: true })
                        break
                    default:
                        break
                }
            })
        }

        tempTableFields.push({ key: 'nameRu', label: 'Наименование КБК', active: true })

        this.indicators.filter(it => it.active).forEach(indicator => {
            switch (indicator.key) {
                case 'plan':
                    tempTableFields.push({ key: 'plan', label: this.indicatorsNameMap.plan, active: true })
                    break
                case 'fact':
                    tempTableFields.push({ key: 'fact', label: this.indicatorsNameMap.fact, active: true })
                    break
                case 'delta1':
                    tempTableFields.push({ key: 'delta1', label: this.indicatorsNameMap.delta1, active: true })
                    break
                case 'delta2':
                    tempTableFields.push({ key: 'delta2', label: this.indicatorsNameMap.delta2, active: true })
                    break
                case 'reasons':
                    tempTableFields.push({ key: 'reasons', label: this.indicatorsNameMap.reasons, active: true })
                    break
                default:
                    break
            }
        })

        this.tableFields = tempTableFields
    }

    public formatDigitsGrouping = formatDigitsGrouping
}
